body {
  background-color: #efefef;
}

.App {
  color: #404040;
  font-family: 'Oswald', sans-serif;
  text-align: center;

  a {
    color: #333;
  }

  h1 {
    font-size: 3rem;
  }

  button {
    border: 1px solid;
    border-color: #ccc #ccc #bbb;
    border-radius: 3px;
    background: #e6e6e6;
    color: rgba(0, 0, 0, .8);
    cursor: pointer;
    font-size: 20px;
    line-height: 1;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    padding: 20px;
  }

  footer {
    margin-top: 40px;
    text-align: center;
    width: 100%;
  }

  p.result {
    font-size: 2rem;
    margin-bottom: 0px;
  }
}